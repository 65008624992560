  .formContainer{
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: var(--appColor);
    height: 100vh;
    background-color: white;
    padding: 0pz 60pz ;
    border-radius: 20px;
  }
  .formTitle{
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    background: none;
    margin-bottom: 20px;
  
  }
  form{
    width: 500px;
    margin: 0 auto;
    margin-top: 20px;
    align-items: center;
    background: transparent;
  }
  .contain{
    margin-left: 30px;
    background: transparent;
  }
  
  #uploadbtn{
  
    color: black;
    background:transparent;
    text-align: center;
    padding: 15px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-left: 30px;
  }
  
  .formBtn{
    width: 400px;
    padding: 10px;
    border: 2px solid gray;
    background:white;
    color: black;
    font-size: 18px;
    border-radius: 10px ;
    margin-left: 50px;
  }
  .formBtn:hover{
    background-color:darkorange;
  }

  .FormPage{
    background-color: var(--appColor);
    padding: 2rem;
  }

  @media  screen and (max-width: 768px) {
    form{
      width: 320px;
    }
    #uploadbtn{
  
      color: black;
      background:transparent;
      text-align: center;
      padding: 1px;
      border: none;
      font-size: 12px;
      cursor: pointer;
      margin-left: 10px;
    }

    .formBtn{
      width: 200px;
      padding: 10px;
      margin-top: 1rem;
      border: 2px solid gray;
      background:white;
      color: black;
      font-size: 18px;
      border-radius: 10px ;
      margin-left: 60px;
    }

    .contain{
      margin-left: 80px;
      background: transparent;
    }

}