.input{
    padding: 15px;
    margin: 10px 0px;
    border-radius: 10px;
    border: 2px solid grey;
    background:white;
    width: 150%;

   
}
label{
    color:black;
    background: none;
    font-size: 18px;
    font-weight: bold;
}
.formInput{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: 280px;
    background: transparent;

}
.error{
    font-size: 10px;
    padding: 3px;
    color: red;
    background: transparent;
    display: none;
  }
input:invalid[focused="true"]~span{
    display: block;
}

@media  screen and (max-width: 768px) {
    
    .input{
        width: 70%;
        margin: auto;
    }

    .formInput{
        align-items: center;
        justify-content: center;
    }
}